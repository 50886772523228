import { createBrowserRouter, Navigate, Outlet, RouterProvider, useRouteError } from "react-router-dom";
import App from "../App";
import Dashboard from "../pages/dashboard/Dashboard";
import Empresas from "../pages/empresas/Empresas";
import Cuestionarios from "../pages/cuestionarios/Cuestionarios";
import Encuestas from "../pages/encuestas/Encuestas";
import EmpresasDetalle from "../pages/empresas/EmpresasDetalle";
import CuestionarioDetalle from "../pages/cuestionarios/CuestionarioDetalle";
import Preguntas from "../pages/cuestionarios/preguntas/Preguntas";
import Preview from "../pages/cuestionarios/preview/Preview";

const Error = () => {
	const error: any = useRouteError()

	return (
		<App>
			<div>{error?.message ?? 'Error desconocido'}</div>
		</App>
	)
}

const MainLayout = () => {
	return (
		<App>
			<Outlet />
		</App>
	)
}

const router = createBrowserRouter([
	{
		path: "/",
		element: <MainLayout />,
		children: [
			{
				path: `/`,
				index: true,
				element: (
					<Navigate to="/empresas" replace />
				),
			},
			{
				path: `/dashboard`,
				element: (
					<Dashboard />
				),
			},
			{
				path: `/empresas`,
				children: [
					{index: true, element: <Empresas />},
					{path: `:empresaId`, element: <EmpresasDetalle />},
				]
			},
			{
				path: `/cuestionarios`,
				children: [
					{index: true, element: <Cuestionarios />},
					{path: `:cuestionarioId`, element: <CuestionarioDetalle />},
					{path: `:cuestionarioId/preview`, element: <Preview />},
					{path: `:cuestionarioId/temas/:temaId/preguntas`, element: <Preguntas />},
				]
			},
			
			{
				path: `/encuestas`,
				element: (
					<Encuestas />
				),
			},
		],
		errorElement: <Error />,
	},
]);

function Routes() {
	return <RouterProvider router={router} />
}

export default Routes