import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { Badge, Button, Checkbox, Input, Loading, Modal, Pagination, Rating, Select, Table, Tooltip } from "react-daisyui";
import { useNavigate, useParams } from "react-router-dom";
import { Pregunta, Tema } from "../interface";
import Swal from "sweetalert2";
import { useDebouncedCallback } from "use-debounce";
import styles from "./Preguntas.module.scss";

export default function Preguntas() {
    const [open, setOpen] = useState(false);
    const [search, setSearch] = useState<string>('');
    const [total, setTotal] = useState(0)
    const [limit, setLimit] = useState(20)
    const [page, setPage] = useState(1)
    const [loading, setLoading] = useState<boolean>(false)
    const [saveLoading, setSaveLoading] = useState<boolean>(false);

    const [texto_pregunta, setTextoPregunta] = useState<string>('');
    const [detalle, setDetalle] = useState<string>('');
    const [informativa, setInformativa] = useState<boolean>(false);
    const [respuesta_correcta, setRespuestaCorrecta] = useState<boolean>(false);
    const [pregunta_activo, setPreguntaActivo] = useState<boolean>(false);
    const [peso, setPeso] = useState<number>(0);
    const [orden, setOrden] = useState<number>(0);
    const [sub_orden, setSubOrden] = useState<number>(0);

    const detailModal = useRef<HTMLDialogElement>(null);
    const navigate = useNavigate();

    const { cuestionarioId, temaId } = useParams()
    const [tema, setTema] = useState<Tema | null>(null);
    const [pregunta, setPregunta] = useState<Pregunta | null>(null);
    const [preguntas, setPreguntas] = useState<Array<Pregunta>>([]);

    const renderPageNumbers = () => {
        const pageNumbers = [];
        const maxPageButtons = 5;
        const totalPages = Math.ceil(total / limit);
        const maxPage = Math.min(totalPages, maxPageButtons);
        const middlePage = Math.ceil(maxPage / 2);
        const startPage = Math.max(1, page - middlePage);
        const endPage = Math.min(totalPages, startPage + maxPage - 1);

        for (let i = startPage; i <= endPage; i++) {
            pageNumbers.push(
                <Button size="sm" key={i} onClick={() => setPage(i)} className={["join-item"].join(' ')} active={i === page}>
                    {i}
                </Button>
            );
        }

        return pageNumbers;
    }

    const descargarInfoTema = () => {
        axios.get(`/api/cuestionarios/${cuestionarioId}/temas/${temaId}`)
            .then((response) => {
                setTema(response.data.tema);
            })
            .catch((error) => {
                console.error("Error al cargar los datos del tema:", error);
            })
            .finally(() => {
                setLoading(false);
            });
    }

    const descargarPreguntas = () => {
        axios.get(`/api/temas/${temaId}/preguntas`)
            .then((response) => {
                setPreguntas(response.data.data);
                setTotal(response.data.total);
                console.log(response.data.data);
            })
            .catch((error) => {
                console.error("Error al cargar las preguntas del tema:", error);
            })
            .finally(() => {
                setLoading(false);
            });
    }

    const guardarPregunta = () => {
        axios(`/api/temas/${temaId}/preguntas${pregunta && pregunta.id_pregunta ? `/${pregunta.id_pregunta}` : ''}`, {
            method: pregunta && pregunta.id_pregunta ? 'PUT' : 'POST',
            data: {
                ...pregunta,
                texto_pregunta,
                detalle,
                informativa,
                respuesta_correcta,
                pregunta_activo,
                peso,
                orden,
                sub_orden
            }
        }).then(() => {
            Swal.fire({
                heightAuto: false,
                icon: 'success',
                title: '¡Listo!',
                text: 'La pregunta se ha guardado correctamente'
            });
        }).finally(() => {
            setSaveLoading(false);
            setOpen(false);
            detailModal.current?.close();
            setPregunta(null);
            setTextoPregunta('');
            setDetalle('');
            setInformativa(false);
            setRespuestaCorrecta(false);
            setPreguntaActivo(false);
            setPeso(0);
            setOrden(0);
            setSubOrden(0);
            descargarPreguntas();
        });
    }
    const editarPregunta = (pregunta: Pregunta) => {
        setPregunta(pregunta);
        setTextoPregunta(pregunta.texto_pregunta || '');
        setDetalle(pregunta.detalle || '');
        setInformativa(pregunta.informativa || false);
        setRespuestaCorrecta(pregunta.respuesta_correcta || false);
        setPreguntaActivo(pregunta.pregunta_activo || false);
        setPeso(pregunta.peso || 0);
        setOrden(pregunta.orden || 0);
        setSubOrden(pregunta.sub_orden || 0);
        detailModal.current?.showModal();
    }
    const eliminarPregunta = (pregunta: Pregunta) => {
        Swal.fire({
            heightAuto: false,
            icon: 'warning',
            title: '¿Estás seguro?',
            text: 'Esta acción eliminará el tema de forma permanente.',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sí, eliminar',
            cancelButtonText: 'Cancelar',
            reverseButtons: true,
        }).then((result) => {
            if (result.isConfirmed) {
                axios.delete(`/api/temas/${temaId}/preguntas/${pregunta.id_pregunta}`).then(() => {
                    Swal.fire({
                        heightAuto: false,
                        icon: 'success',
                        title: '¡Listo!',
                        text: 'La pregunta se ha eliminado correctamente'
                    });
                }).finally(() => {
                    descargarPreguntas();
                });
            }
        });
    }

    useEffect(() => {
        descargarInfoTema();
        descargarPreguntas();
    }, [temaId, limit, page, search, open]);

    const cerrarModal = () => {
        setOpen(false);
        detailModal.current?.close();
    }

    const debounced = useDebouncedCallback((value) => {
        setSearch(value);
    }, 500);

    return (

        <div className={styles.preguntas_wrapper}>
            <h3 className={styles.title}>Preguntas para el tema {tema?.nombre || 'Cargando...'}</h3>
            <div className={styles.actions}>
                <Button onClick={() => navigate(-1)} color="ghost" size='sm'>
                    <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="currentColor"><path d="m313-440 196 196q12 12 11.5 28T508-188q-12 11-28 11.5T452-188L188-452q-6-6-8.5-13t-2.5-15q0-8 2.5-15t8.5-13l264-264q11-11 27.5-11t28.5 11q12 12 12 28.5T508-715L313-520h447q17 0 28.5 11.5T800-480q0 17-11.5 28.5T760-440H313Z" /></svg>
                </Button>
                <Input className='w-full' placeholder="Buscar" size="sm" onChange={(e) => debounced(e.target.value)} />
                <Button color="primary" size='sm' onClick={() => setOpen(true)}>Agregar pregunta</Button>
            </div>
            <div className="h-full" style={{ overflowY: 'auto', overflowX: 'hidden' }}>
                <div style={{ overflowY: 'auto', overflowX: 'hidden' }}>
                    {loading ? <div className={styles.loading}><Loading color="primary" /></div> :
                        <Table zebra pinRows>
                            <Table.Head className="th_acciones">
                                <span />
                                <span>Texto pregunta</span>
                                <span>Detalle</span>
                                <span>Informativa</span>
                                <span>Respuesta correcta</span>
                                <span>Estado</span>
                                <span>Peso</span>
                                <span>Orden</span>
                                <span>Sub Orden</span>
                                <span>Acciones</span>
                            </Table.Head>

                            <Table.Body>
                                {preguntas.map((pregunta, item) => (
                                    <Table.Row key={item} hover>
                                        <span>{pregunta.id_pregunta}</span>
                                        <span>{pregunta.texto_pregunta}</span>
                                        <span>{pregunta.detalle}</span>
                                        <span>{pregunta.informativa === true ? <Badge color="info">Si</Badge> : <Badge color="primary">No</Badge>}</span>
                                        <span>{pregunta.respuesta_correcta === true ? <Badge color="info">Si</Badge> : <Badge color="primary">No</Badge>}</span>
                                        <span>{pregunta.pregunta_activo === true ? <Badge color="success">Activo</Badge> : <Badge color="error">Inactivo</Badge>}</span>
                                        {!pregunta.informativa ? (
                                            <span>
                                                <Rating value={pregunta.peso || 0} color="warning" size="sm">
                                                    <Rating.Item name="rating-2" className="mask mask-star-2 bg-orange-400" />
                                                    <Rating.Item name="rating-2" className="mask mask-star-2 bg-orange-400" />
                                                    <Rating.Item name="rating-2" className="mask mask-star-2 bg-orange-400" />
                                                    <Rating.Item name="rating-2" className="mask mask-star-2 bg-orange-400" />
                                                    <Rating.Item name="rating-2" className="mask mask-star-2 bg-orange-400" />
                                                </Rating>
                                            </span>
                                        ) : (
                                            <span>-</span>
                                        )}
                                        <span>{pregunta.orden}</span>
                                        <span>{pregunta.sub_orden}</span>
                                        <span>
                                            <div className="flex space-x-2 items-center gap-2">
                                                <Tooltip message="Editar">
                                                    <Button size="sm" shape="square" color="secondary" onClick={() => editarPregunta(pregunta)}>
                                                        <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px"><path d="M200-200h57l391-391-57-57-391 391v57Zm-40 80q-17 0-28.5-11.5T120-160v-97q0-16 6-30.5t17-25.5l505-504q12-11 26.5-17t30.5-6q16 0 31 6t26 18l55 56q12 11 17.5 26t5.5 30q0 16-5.5 30.5T817-647L313-143q-11 11-25.5 17t-30.5 6h-97Zm600-584-56-56 56 56Zm-141 85-28-29 57 57-29-28Z" /></svg>
                                                    </Button>
                                                </Tooltip>
                                                <Tooltip message="Eliminar">
                                                    <Button size="sm" shape="square" color="error" onClick={() => eliminarPregunta(pregunta)}>
                                                        <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px"><path d="M280-120q-33 0-56.5-23.5T200-200v-520q-17 0-28.5-11.5T160-760q0-17 11.5-28.5T200-800h160q0-17 11.5-28.5T400-840h160q17 0 28.5 11.5T600-800h160q17 0 28.5 11.5T800-760q0 17-11.5 28.5T760-720v520q0 33-23.5 56.5T680-120H280Zm400-600H280v520h400v-520ZM400-280q17 0 28.5-11.5T440-320v-280q0-17-11.5-28.5T400-640q-17 0-28.5 11.5T360-600v280q0 17 11.5 28.5T400-280Zm160 0q17 0 28.5-11.5T600-320v-280q0-17-11.5-28.5T560-640q-17 0-28.5 11.5T520-600v280q0 17 11.5 28.5T560-280ZM280-720v520-520Z" /></svg>
                                                    </Button>
                                                </Tooltip>
                                            </div>
                                        </span>
                                    </Table.Row>
                                ))}
                            </Table.Body>
                        </Table>
                    }
                </div>
            </div>

            <div className={styles.pagination}>
                <div className={styles.cantidad}>
                    <small >Cantidad por página:</small>
                    <Select size="sm" id="itemsPerPage" value={limit} onChange={(e) => setLimit(parseInt(e.target.value))} >
                        <option value={5}>5</option>
                        <option value={10}>10</option>
                        <option value={20}>20</option>
                    </Select>
                </div>
                <Pagination className="flex justify-center">
                    <Button size="sm" onClick={() => setPage(page - 1)} disabled={page === 1} className="join-item">
                        Anterior
                    </Button>
                    {renderPageNumbers()}
                    <Button size="sm" onClick={() => setPage(page + 1)} disabled={page === Math.ceil(total / limit)} className="join-item">
                        Siguiente
                    </Button>
                </Pagination>
            </div>
            <Modal open={open} ref={detailModal}>
                <form method="dialog">
                    <Button size="sm" color="ghost" shape="circle" className="absolute" style={{ top: '16px', right: '16px' }} onClick={cerrarModal}>
                        <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="currentColor"><path d="M480-424 284-228q-11 11-28 11t-28-11q-11-11-11-28t11-28l196-196-196-196q-11-11-11-28t11-28q11-11 28-11t28 11l196 196 196-196q11-11 28-11t28 11q11 11 11 28t-11 28L536-480l196 196q11 11 11 28t-11 28q-11 11-28 11t-28-11L480-424Z" /></svg>
                    </Button>
                </form>
                <Modal.Header className="font-bold">{pregunta?.id_pregunta ? 'Editar pregunta' : 'Nueva pregunta'}</Modal.Header>
                <Modal.Body>
                    {saveLoading ? <Loading color='primary' /> : <>
                        <div>
                            <div className="form-control mb-2">
                                <label className="label">
                                    <span className="label-text">Texto pregunta</span>
                                </label>
                                <textarea maxLength={500} style={{ minHeight: '80px' }} placeholder="Texto pregunta" className="input input-bordered" onChange={(e) => setTextoPregunta(e.target.value)} value={texto_pregunta} />
                            </div>
                            <div className="form-control mb-2">
                                <label className="label">
                                    <span className="label-text">Detalle</span>
                                </label>
                                <textarea maxLength={500} style={{ minHeight: '80px' }} placeholder="Detalle" className="input input-bordered" onChange={(e) => setDetalle(e.target.value)} value={detalle} />
                            </div>
                            <div className={styles.checkboxs}>
                                <div className={['form-control mb-2', styles.checkbox].join(' ')}>
                                    <Checkbox color="primary" checked={informativa} onChange={(e) => setInformativa(e.target.checked)} id="informativo" />
                                    <label className="label" htmlFor="informativo">
                                        <span className="label-text" style={{ fontSize: '18px' }}>Informativa</span>
                                    </label>
                                </div>
                                <div className={['form-control mb-2', styles.checkbox].join(' ')}>
                                    <Checkbox color="primary" checked={pregunta_activo} onChange={(e) => setPreguntaActivo(e.target.checked)} id="activo" />
                                    <label className="label" htmlFor="activo">
                                        <span className="label-text" style={{ fontSize: '18px' }}>Activo</span>
                                    </label>
                                </div>
                                <div className={['form-control mb-2', styles.checkbox].join(' ')}>
                                    <Checkbox color="primary" checked={respuesta_correcta} onChange={(e) => setRespuestaCorrecta(e.target.checked)} id="respuesta_correcta" />
                                    <label className="label" htmlFor="respuesta_correcta">
                                        <span className="label-text" style={{ fontSize: '18px' }}>Respuesta correcta</span>
                                    </label>
                                </div>
                            </div>
                            <div className={styles.checkboxs}>
                                <div className="form-control mb-2">
                                    <label className="label">
                                        <span className="label-text">Orden</span>
                                    </label>
                                    <Input size="sm" type="number" style={{ width: '100%' }} placeholder="Orden" className="input input-bordered" onChange={(e) => setOrden(Number(e.target.value))} value={orden} />
                                </div>
                                <div className="form-control mb-2">
                                    <label className="label">
                                        <span className="label-text">Sub orden</span>
                                    </label>
                                    <Input size="sm" type="number" style={{ width: '100%' }} placeholder="Orden" className="input input-bordered" onChange={(e) => setSubOrden(Number(e.target.value))} value={sub_orden} />
                                </div>
                                {!informativa &&
                                    <div className="form-control mb-2">
                                        <label className="label">
                                            <span className="label-text">Peso</span>
                                        </label>
                                        <Rating value={peso} onChange={(newRating: number) => setPeso(newRating)}>
                                            <Rating.Item name="rating-2" className="mask mask-star-2 bg-orange-400" />
                                            <Rating.Item name="rating-2" className="mask mask-star-2 bg-orange-400" />
                                            <Rating.Item name="rating-2" className="mask mask-star-2 bg-orange-400" />
                                            <Rating.Item name="rating-2" className="mask mask-star-2 bg-orange-400" />
                                            <Rating.Item name="rating-2" className="mask mask-star-2 bg-orange-400" />
                                        </Rating>
                                    </div>
                                }
                            </div>
                        </div>
                    </>}
                </Modal.Body>
                <Modal.Actions>
                    <Button color="primary" fullWidth onClick={guardarPregunta}>Guardar</Button>
                </Modal.Actions>
            </Modal>
        </div>

    )
}