import styles from './Main.module.scss'
import { useEffect, useRef, useState } from 'react';
import Highcharts from "highcharts/highstock";
import HighchartsReact, { HighchartsReactRefObject } from 'highcharts-react-official';
import HighchartsExporting from 'highcharts/modules/exporting';
import axios from '../../../api/axios';
import { Input } from 'react-daisyui';

// Inicializar el módulo de exportación
if (typeof Highcharts === 'object') {
	HighchartsExporting(Highcharts);
}

type NaturalGasProductionByDepositData = {
	anio: number,
	mes: number,
	cantidad: string,
	diaria: string,
}

type DepositsData = {
	id_area_yacimiento: string,
	area_yacimiento: string,
}


export default function NaturalGasProductionByDeposit() {
	Highcharts.setOptions({
		lang: {
			months: [
				'Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio',
				'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'
			],
			shortMonths: [
				'Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun',
				'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'
			],
			decimalPoint: ",",
			loading: 'Cargando...',
			viewFullscreen: "Ver en pantalla completa",
			printChart: "Imprimir gráfico",
			downloadPNG: "Descargar imagen PNG",
			downloadJPEG: "Descargar imagen JPEG",
			downloadPDF: "Descargar documento PDF",
			downloadSVG: "Descargar imagen SVG",
		},
	})
	const ref = useRef<HighchartsReactRefObject>(null);
	const [data, setData] = useState<Array<NaturalGasProductionByDepositData>>([]);
	const [deposits, setDeposits] = useState<Array<DepositsData>>([]);
	const [deposit, setDeposit] = useState<string>('');
	const [searchTerm, setSearchTerm] = useState<string>('');
	const [filteredDeposits, setFilteredDeposits] = useState<Array<DepositsData>>([]);
	const [showDropdown, setShowDropdown] = useState<boolean>(false);

	const [options, setOptions] = useState<Highcharts.Options>({
		colors: ['#197496'],
		chart: {
			backgroundColor: '#252b33',
		},
		title: {
			text: 'Producción de gas natural por yacimiento',
			align: 'left',
			style: {
				color: 'currentColor',
				font: 'bold 16px "Trebuchet MS", Verdana, sans-serif'
			}
		},
		xAxis: {
			labels: {
				style: {
					color: '#a6adbb',
				},
			},
		},
		yAxis: {
			title: {
				text: 'Millones de m3 por día',
				style: {
					color: 'currentColor',
					font: 'bold 14px "Trebuchet MS", Verdana, sans-serif'
				}
			},
			labels: {
				style: {
					color: '#a6adbb',
				}
			}
		},
		plotOptions: {
			column: {
				dataLabels: {
					enabled: true,
					inside: true,
					verticalAlign: 'top',
				}
			}
		},
		tooltip: {
			backgroundColor: '#333333',
			style: {
				color: '#FFFFFF',
			},
			borderColor: '#197496',
		},
		legend: {
			itemStyle: {
				color: '#a6adbb',
			},
			itemHoverStyle: {
				color: '#768093'
			}
		}
	});

	const downloadData = async (selectedDeposit: string) => {
		const query = selectedDeposit ? `?filters[id_area_yacimiento]=${selectedDeposit}` : '';
		axios.get(`/api/dashboard/natural-gas-production${query}`).then((response) => {
			setData(response.data);
		});
	};

	const downloadDeposits = async () => {
		axios.get(`/api/dashboard/deposits`).then((response) => {
			setDeposits(response.data);
			setFilteredDeposits(response.data);
		});
	};

	useEffect(() => {
		const series = data.map((item) => [
			Date.UTC(item.anio, item.mes - 1),
			parseFloat(parseFloat(item.diaria).toFixed(2))
		]);

		series.sort((a, b) => a[0] - b[0]);

		setOptions({
			...options,
			series: [{
				type: 'column',
				name: 'Producción',
				data: series,
			}],
		});

		if (ref.current) {
			ref.current.chart?.update({
				rangeSelector: {
					selected: 1,
					buttons: [
						{
							type: 'month',
							count: 6,
							text: '6m'
						},
						{
							type: 'year',
							count: 1,
							text: '1y',
						},
						{
							type: 'month',
							count: 18,
							text: '18m'
						},
						{
							type: 'all',
							text: 'All'
						}
					]
				}
			});
		}
	}, [data]);

	useEffect(() => {
		downloadData(deposit);
	}, [deposit]);

	useEffect(() => {
		downloadDeposits();
	}, []);

	useEffect(() => {
		if (searchTerm === '') {
			setFilteredDeposits(deposits);
		} else {
			setFilteredDeposits(
				deposits.filter(deposit =>
					deposit.area_yacimiento.toLowerCase().includes(searchTerm.toLowerCase())
				)
			);
		}
	}, [searchTerm, deposits]);

	useEffect(() => {
		const handleClickOutside = (event: MouseEvent) => {
			if (refInput.current && !refInput.current.contains(event.target as Node)) {
				setShowDropdown(false);
			}
		};
		document.addEventListener('mousedown', handleClickOutside);
		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, []);

	const refInput = useRef<HTMLDivElement>(null);

	const clearSearch = () => {
		setSearchTerm('');
		setDeposit('');
		setShowDropdown(false);
	};

	return (
		<div className={[styles.dashboard_wrapper].join(' ')}>
			<div ref={refInput} style={{ position: 'relative' }}>
				<Input
					type="text"
					placeholder="Buscar yacimiento..."
					value={searchTerm}
					onChange={(e) => {
						setSearchTerm(e.target.value);
						if (e.target.value === '') {
							setDeposit('');
						}
					}}
					onClick={() => setShowDropdown(true)}
					size='sm'
					className='w-full'
				/>
				{searchTerm && (
					<button
						onClick={clearSearch}
						className={styles.clear_search}
					>
						<svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="currentColor"><path d="M480-424 284-228q-11 11-28 11t-28-11q-11-11-11-28t11-28l196-196-196-196q-11-11-11-28t11-28q11-11 28-11t28 11l196 196 196-196q11-11 28-11t28 11q11 11 11 28t-11 28L536-480l196 196q11 11 11 28t-11 28q-11 11-28 11t-28-11L480-424Z"/></svg>
					</button>
				)}

				{showDropdown && filteredDeposits.length > 0 && (
					<ul className={styles.dropdown}>
						{filteredDeposits.map((deposit) => (
							<li
								key={deposit.id_area_yacimiento}
								className={styles.dropdown_item}
								onClick={() => {
									setDeposit(deposit.id_area_yacimiento);
									setSearchTerm(deposit.area_yacimiento);
									setShowDropdown(false);
								}}
							>
								{deposit.area_yacimiento}
							</li>
						))}
					</ul>
				)}
			</div>

			<div>
				<HighchartsReact
					highcharts={Highcharts}
					constructorType={"stockChart"}
					options={options}
					ref={ref}
				/>
			</div>
		</div>
	);
}
