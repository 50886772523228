import axios from "axios";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom"
import styles from "./Preview.module.scss"
import { Loading } from "react-daisyui";

export default function Preview() {
    const [preview, setPreview] = useState<any>(null);
    const { cuestionarioId } = useParams()

    const descargarInfoPreview = () => {
        axios.get(`/api/cuestionarios/${cuestionarioId}/preview`)
            .then((response) => {
                setPreview(response.data.cuestionario);
            })
            .catch((error) => {
                console.error(error);
            })
    }

    useEffect(() => {
        descargarInfoPreview();
    }, [])


    return (
        <div className={styles.preview_wrapper}>
            {preview ? (
                <div className={styles.preview}>
                    <h1 className={styles.nombre}>{preview.nombre}</h1>
                    <p>{preview.descripcion}</p>
                    <img src={`/api/cuestionarios/imagen/${preview.imagen}`} className={styles.imagen} alt={preview.nombre} />
                    {preview.temas.map((tema: any) => (
                        <div key={tema.id} className={styles.tema}>
                            <h2>{tema.nombre_mostrable}</h2>
                            <div className={styles.datos}>
                                <p>Orden: {tema.orden}</p>
                                <p>Peso: {tema.peso}</p>
                            </div>

                            <div className={styles.preguntas}>
                                <ul className={styles.pregunta}>
                                    {tema.preguntas.map((pregunta: any) => (
                                        <li key={pregunta.id_pregunta}>{pregunta.texto_pregunta}</li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    ))}
                </div>
            ) : (
                <div className={styles.loading}>
                    <Loading color="primary" />
                </div>

            )}
        </div>
    )
}